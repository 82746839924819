.content { margin:0; width:100%; height:200px; padding:0; overflow:auto;}
/*.content p:nth-child(even){color:#999; font-family:Georgia,serif; font-size:17px; font-style:italic;}
.content p:nth-child(3n+0){color:#c96;}*/
.loading { opacity:0.3; }
.event_inner { padding:20px; }
/* basic scrollbar styling */
/* vertical scrollbar */
.mCSB_container { width:auto; margin-right:15px; overflow:hidden; }
.mCSB_container.mCS_no_scrollbar { margin-right:0; }
.mCS_disabled>.mCustomScrollBox>.mCSB_container.mCS_no_scrollbar,
.mCS_destroyed>.mCustomScrollBox>.mCSB_container.mCS_no_scrollbar { margin-right:30px; }
.mCustomScrollBox>.mCSB_scrollTools { width:16px; height:100%; top:0; right:-5px; }
.mCSB_scrollTools .mCSB_draggerContainer { position:absolute; top:0; left:0; bottom:0; right:0; height:auto; }
.mCSB_scrollTools a+.mCSB_draggerContainer { margin:0; }
.mCSB_scrollTools .mCSB_draggerRail { width:2px; height:100%; margin:0 auto; -webkit-border-radius:10px; -moz-border-radius:10px; border-radius:10px; }
.mCSB_scrollTools .mCSB_dragger { cursor:pointer; width:100%; height:30px; }
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar { width:5px; height:100%; margin:0 auto; -webkit-border-radius:10px; -moz-border-radius:10px; border-radius:10px; text-align:center; }
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown { display:none; position:relative; height:20px; overflow:hidden; margin:0 auto; cursor:pointer; }
.mCSB_scrollTools .mCSB_buttonDown { top:100%; margin-top:-40px; }
/* horizontal scrollbar */
.mCSB_horizontal>.mCSB_container { height:auto; margin-right:0; margin-bottom:30px; overflow:hidden; }
.mCSB_horizontal>.mCSB_container.mCS_no_scrollbar { margin-bottom:0; }
.mCS_disabled>.mCSB_horizontal>.mCSB_container.mCS_no_scrollbar,
.mCS_destroyed>.mCSB_horizontal>.mCSB_container.mCS_no_scrollbar { margin-right:0; margin-bottom:30px; }
.mCSB_horizontal.mCustomScrollBox>.mCSB_scrollTools { width:100%; height:16px; top:auto; right:auto; bottom:0; left:0; overflow:hidden; }
.mCSB_horizontal>.mCSB_scrollTools a+.mCSB_draggerContainer { margin:0 20px; }
.mCSB_horizontal>.mCSB_scrollTools .mCSB_draggerRail { width:100%; height:2px; margin:7px 0; -webkit-border-radius:10px; -moz-border-radius:10px; border-radius:10px; }
.mCSB_horizontal>.mCSB_scrollTools .mCSB_dragger { width:30px; height:100%; }
.mCSB_horizontal>.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar { width:100%; height:4px; margin:6px auto; -webkit-border-radius:10px; -moz-border-radius:10px; border-radius:10px; }
.mCSB_horizontal>.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_horizontal>.mCSB_scrollTools .mCSB_buttonRight { display:block; position:relative; width:20px; height:100%; overflow:hidden; margin:0 auto; cursor:pointer; float:left; }
.mCSB_horizontal>.mCSB_scrollTools .mCSB_buttonRight { margin-left:-40px; float:right; }
.mCustomScrollBox { -ms-touch-action:none; /*MSPointer events - direct all pointer events to js*/ }
/* default scrollbar colors and backgrounds (default theme) */
.mCustomScrollBox>.mCSB_scrollTools { opacity:0.75; filter:"alpha(opacity=75)"; -ms-filter:"alpha(opacity=75)"; /* old ie */ }
.mCustomScrollBox:hover>.mCSB_scrollTools { opacity:1; filter:"alpha(opacity=100)"; -ms-filter:"alpha(opacity=100)"; /* old ie */ }
.mCSB_scrollTools .mCSB_draggerRail { background:#fff; /* rgba fallback */ filter:"alpha(opacity=40)"; -ms-filter:"alpha(opacity=40)"; /* old ie */ }
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar { background:#95b3c6; /* rgba fallback */ filter:"alpha(opacity=75)"; -ms-filter:"alpha(opacity=75)"; /* old ie */ }
.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar { background:#95b3c6; filter:"alpha(opacity=85)"; -ms-filter:"alpha(opacity=85)"; /* old ie */ }
.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar { background:#95b3c6; filter:"alpha(opacity=90)"; -ms-filter:"alpha(opacity=90)"; /* old ie */ }
.mCSB_scrollTools .mCSB_buttonUp:hover,
.mCSB_scrollTools .mCSB_buttonDown:hover,
.mCSB_scrollTools .mCSB_buttonLeft:hover,
.mCSB_scrollTools .mCSB_buttonRight:hover { opacity:0.75; filter:"alpha(opacity=75)"; -ms-filter:"alpha(opacity=75)"; /* old ie */ }
.mCSB_scrollTools .mCSB_buttonUp:active,
.mCSB_scrollTools .mCSB_buttonDown:active,
.mCSB_scrollTools .mCSB_buttonLeft:active,
.mCSB_scrollTools .mCSB_buttonRight:active { opacity:0.9; filter:"alpha(opacity=90)"; -ms-filter:"alpha(opacity=90)"; /* old ie */ }
.mCS-dark-thick>.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar { width:6px; background:#000; /* rgba fallback */ background:rgba(0, 0, 0, 0.75); -webkit-border-radius:2px; -moz-border-radius:2px; border-radius:2px; }
.mCS-dark-thick.mCSB_horizontal>.mCSB_scrollTools .mCSB_draggerRail { width:100%; height:4px; margin:6px 0; }
.mCS-dark-thick.mCSB_horizontal>.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar { width:100%; height:6px; margin:5px auto; }
.mCS-dark-thick>.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar { background:rgba(0, 0, 0, 0.85); }
.mCS-dark-thick>.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thick>.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar { background:rgba(0, 0, 0, 0.9); }
.mCS-dark-thick>.mCSB_scrollTools .mCSB_buttonUp { background-position:-96px 0; }
.mCS-dark-thick>.mCSB_scrollTools .mCSB_buttonDown { background-position:-96px -20px; }
.mCS-dark-thick>.mCSB_scrollTools .mCSB_buttonLeft { background-position:-100px -40px; }
.mCS-dark-thick>.mCSB_scrollTools .mCSB_buttonRight { background-position:-100px -56px; }
